<template>
    <div ref="gaugeChart" style="width: 100%; height: 100%;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: {
        initialValue: {
            type: Number,
            required: true,
        },
        valueName: {
            type: String,
            default: '完成率',
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.initChart();
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$refs.gaugeChart);
            this.chart.setOption(this.getOption(this.initialValue, this.valueName));
        },
        getOption(value, name) {
            return {
                tooltip: {
                    formatter: '{a} <br/>{b} : {c}%',
                },
                series: [
                    {
                        name: '业务指标',
                        type: 'gauge',
                        detail: {
                            formatter: '{value}%',
                            fontSize: 20,
                        },
                        data: [{ value: value, name: name }],
                        axisLine: {
                            lineStyle: {
                                color: [[0.3, '#ff4500'], [0.7, '#ffa500'], [1, '#32cd32']],
                                width: 15,
                            },
                            roundCap: true, // 设置圆形指针
                        },
                        pointer: {
                            length: '80%',
                            width: 8, // 加宽指针
                        },
                    },
                ],
            };
        },
        updateValue(newValue) {
            this.chart.setOption({
                series: [{
                    data: [{ value: newValue, name: this.valueName }],
                }],
            });
        },
    },
};
</script>

<style scoped></style>
