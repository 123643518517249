<!-- WorkOrderChart.vue -->
<template>
    <div ref="chart" :style="{ width: '100%', height: '400px' }"></div>
</template>

<script>
import echarts from 'echarts';

export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    mounted() {
        this.initChart();
        window.addEventListener('resize', this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeChart);
    },
    methods: {
        initChart() {
            const myChart = echarts.init(this.$refs.chart);

            const option = {
                title: { text: '工单数量统计' },
                tooltip: {},
                legend: { data: ['工单数量', '超时工单数量', '未超时数量'] },
                xAxis: { type: 'category', data: this.data.map(item => item.month) },
                yAxis: { type: 'value' },
                series: [
                    {
                        name: '工单数量',
                        type: 'bar',
                        data: this.data.map(item => item.total),
                        itemStyle: { color: '#3398DB' }
                    },
                    {
                        name: '超时工单数量',
                        type: 'line',
                        data: this.data.map(item => item.timeout),
                        itemStyle: { color: '#FF4C4C' }
                    },
                    {
                        name: '未超时数量',
                        type: 'line',
                        data: this.data.map(item => item.notTimeout),
                        itemStyle: { color: '#4CAF50' }
                    }
                ]
            };

            myChart.setOption(option);
            this.chartInstance = myChart;
        },
        resizeChart() {
            if (this.chartInstance) {
                this.chartInstance.resize();
            }
        }
    }
};
</script>

<style scoped>
/* 可根据需要添加样式 */
</style>