<template>
  <div>


  <el-row>
    <el-col :span="24">
      <div class="div_card">
        <el-card v-for="(item, index) in monthlyData" :key="index" class="card">
          <div class="month">{{ item.month }}</div>
          <div class="rate">{{ item.rate }}</div>
        </el-card>
      </div>
    </el-col>
  </el-row>
  
  <el-row>
        <el-col :span="24">
          <div class="gauge-container">
            <gauge-chart ref="gaugeChart1" :initial-value="75" value-name="cpu" />
            <gauge-chart ref="gaugeChart2" :initial-value="75" value-name="内存" />
            <gauge-chart ref="gaugeChart3" :initial-value="75" value-name="负载" />
            <gauge-chart ref="gaugeChart4" :initial-value="75" value-name="磁盘" />
          </div>
        </el-col>
  </el-row>
  
  <el-row>
        <el-col :span="12">
          <div class="san-container">
            <el-table 
            :data="tableData" 
            height="400"
            style="width: 100%" 
            class="san-container">
              <el-table-column prop="name" sortable label="名称" align="center"></el-table-column>
              <el-table-column prop="status" sortable label="状态" align="center"></el-table-column>
              <el-table-column prop="usage" sortable  label="使用情况" align="center"></el-table-column>
              <el-table-column prop="usageTime" sortable label="使用时间" align="center"></el-table-column>
            </el-table>
          </div>
        </el-col>
        
        <el-col :span="12">
          <div class="san-container">
            <WorkOrderChart :data="chartData" />
          </div>
        </el-col>
  </el-row>


  </div>

</template>

<script>
import GaugeChart from './components/gaugeDrawing.vue';
import WorkOrderChart  from './components/histogram.vue';

export default {
  components: {
    GaugeChart,
    WorkOrderChart ,
  },
  data() {
    return {
      timer: null,
      monthlyData: [
        { month: '实时待办', rate: '6单' },
        { month: '本月完成量', rate: '101单' },
        { month: '本月SLA达标率', rate: '100%' },
        { month: '今日新增', rate: '10单' },
        { month: '今日完成', rate: '4单' },
        { month: '异常终止/驳回', rate: '0单' },
        { month: '本月已超时', rate: '7单' },
        { month: '上月完成量', rate: '183单' },
      ],
      chartData: [
        { month: '2023-01', total: 120, timeout: 20, notTimeout: 100 },
        { month: '2023-02', total: 132, timeout: 30, notTimeout: 102 },
        { month: '2023-03', total: 101, timeout: 10, notTimeout: 91 },
        { month: '2023-04', total: 134, timeout: 15, notTimeout: 119 },
        { month: '2023-05', total: 90, timeout: 10, notTimeout: 80 },
        { month: '2023-06', total: 230, timeout: 20, notTimeout: 210 },
        { month: '2023-07', total: 210, timeout: 25, notTimeout: 185 },
        { month: '2023-08', total: 150, timeout: 30, notTimeout: 120 }
      ],
      tableData: [
        { name: '设备 A', status: '正常', usage: '80%', usageTime: '2024-09-01 10:00' },
        { name: '设备 B', status: '异常', usage: '60%', usageTime: '2024-09-02 12:00' },
        { name: '设备 C', status: '正常', usage: '50%', usageTime: '2024-09-03 14:00' },
        { name: '设备 D', status: '正常', usage: '90%', usageTime: '2024-09-04 16:00' },
        { name: '设备 E', status: '异常', usage: '20%', usageTime: '2024-09-05 18:00' },
        { name: '设备 F', status: '正常', usage: '75%', usageTime: '2024-09-06 08:00' },
        { name: '设备 G', status: '正常', usage: '85%', usageTime: '2024-09-07 09:30' },
        { name: '设备 H', status: '异常', usage: '40%', usageTime: '2024-09-08 11:15' },
        { name: '设备 I', status: '正常', usage: '95%', usageTime: '2024-09-09 13:45' },
        { name: '设备 J', status: '正常', usage: '70%', usageTime: '2024-09-10 15:00' },
        { name: '设备 K', status: '异常', usage: '30%', usageTime: '2024-09-11 17:30' },
        { name: '设备 L', status: '正常', usage: '60%', usageTime: '2024-09-12 19:00' },
        { name: '设备 M', status: '正常', usage: '80%', usageTime: '2024-09-13 20:15' },
        { name: '设备 N', status: '异常', usage: '10%', usageTime: '2024-09-14 21:00' },
        { name: '设备 O', status: '正常', usage: '90%', usageTime: '2024-09-15 22:30' },
      ]
    };
  },
  mounted() {
    this.startUpdatingValue();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    startUpdatingValue() {
      this.timer = setInterval(() => {
        const randomValue = Math.floor(Math.random() * (95 - 90 + 1)) + 90; // 生成 90 到 95 的随机值
        for (let i = 1; i <= 4; i++) {
          this.$refs[`gaugeChart${i}`].updateValue(randomValue);
        }
      }, 2000);
    },
  },
};
</script>

<style scoped>
.gauge-container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}

.div_card {
  display: flex; /* 使用 flexbox */
  justify-content: center; /* 水平居中 */
  flex-wrap: wrap; /* 如果卡片过多则换行 */
  gap: 10px; /* 卡片之间的间距 */
  margin-left: 2px;
  margin-right: 2px;
}

.card-container {
  display: flex;
  flex-direction: column; /* 确保是垂直排列 */
  align-items: center; /* 水平居中 */
}

.card {
  width: 200px; /* 方框宽度 */
  background-color: #f0f0f0; /* 浅灰色 */
  text-align: center;
  padding: 10px;
  margin: 10px 0; /* 添加上下间距 */
  border-radius: 8px; /* 圆角 */
  box-shadow: 0 2px 4px rgba(179, 179, 179, 0.1); /* 阴影效果 */
}

.month {
  font-size: 20px;
}

.rate {
  color: blue; /* 蓝色字体 */
  font-size: 20px;
  margin-top: 4px;
}

.san-container{
  height: 400px;
}
</style>
